import { getDogfoodClient } from '@gonfalon/dogfood-client';

import { RUMCustomErrorContext } from './types';

export function trackDogfoodError({ isCrash, severity }: RUMCustomErrorContext = {}) {
  getDogfoodClient().track('Gonfalon frontend errors');

  if (isCrash || severity === 'critical' || severity === 'high') {
    getDogfoodClient().track('Gonfalon frontend crashes');
  }
}
